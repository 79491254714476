import { Controller } from '@hotwired/stimulus'

export default class HeaderSearchController extends Controller {
  static targets = ['form', 'input']

  cancel() {
    this.formTarget.classList.remove('is-active')
  }

  submit(e) {
    if (!this.formTarget.classList.contains('is-active')) {
      e.preventDefault()
      this.formTarget.classList.add('is-active')
      this.inputTarget.focus()
    }
  }

  keydown(e) {
    if (e.key === 'Escape' && document.activeElement === this.inputTarget) {
      this.formTarget.classList.remove('is-active')
    }
  }
}
