function closestLineItemGroupID(node) {
  const closestLineItemGroup = node.closest('[id^="line_item_group_"]')
  if (closestLineItemGroup) {
    return closestLineItemGroup.id
  }
}

async function restoreScrollPosition() {
  document.removeEventListener('turbo:load', restoreScrollPosition)
  const targetElement = document.getElementById(document.scrollTargetID)
  if (targetElement) {
    const { default: scrollIntoView } = await import('scroll-into-view-if-needed')
    scrollIntoView(targetElement, { scrollMode: 'if-needed' })
    delete document.scrollTargetID
  }
}

function restoreScrollClickListener(event) {
  if (event.target.classList.contains('js-restore-scroll-position')) {
    const lineItemGroupID = closestLineItemGroupID(event.target)
    if (lineItemGroupID) {
      document.scrollTargetID = lineItemGroupID
      document.addEventListener('turbo:load', restoreScrollPosition)
    }
  }
}

function restoreScrollChangeListener() {
  document.body.querySelectorAll('.js-submit-on-change select, .js-submit-on-change input').forEach((input) => {
    input.addEventListener('change', (event) => {
      if (input.form.checkValidity()) {
        const lineItemGroupID = closestLineItemGroupID(event.target)
        if (lineItemGroupID) {
          document.scrollTargetID = lineItemGroupID
          document.addEventListener('turbo:load', restoreScrollPosition)
        }
        input.form.requestSubmit()
      } else {
        event.target.reportValidity()
      }
    })
  })
}

document.addEventListener('click', restoreScrollClickListener)
document.addEventListener('turbo:load', restoreScrollChangeListener)
