// Allows setting "turbo-reload-after-milliseconds" data attribute on Turbo Frame to reload it after a period of time

import { Turbo } from '@hotwired/turbo-rails'

let timeout = null

function turboReloadAfterMilliseconds() {
  clearTimeout(timeout)

  const reloadElement = document.querySelector('[data-turbo-reload-after-milliseconds]')
  if (reloadElement) {
    timeout = setTimeout(function () {
      Turbo.visit(window.location.toString(), { action: 'replace' })
    }, reloadElement.dataset.turboReloadAfterMilliseconds)
  }
}

window.addEventListener('starfish:render', turboReloadAfterMilliseconds)
