import { Controller } from '@hotwired/stimulus'
import { hide, show } from '../../lib/helpers'

export default class ProductHeroVariantsController extends Controller {
  static targets = [
    'variant',
    'variantsContainer',
    'mainImage',
    'selectedContainer',
    'variantsWrapper',
    'name',
    'price',
    'mainImageLink',
  ]

  handleTouchStart(e) {
    this.touchstartX = e.changedTouches[0].screenX
  }

  handleTouchEnd(e) {
    this.touchendX = e.changedTouches[0].screenX
    this.handleSwipe()
  }

  handleSwipe() {
    let currentVariantIndex = this.variantTargets.findIndex(
      (variant) => variant.getAttribute('data-image') === this.mainImageTarget.src
    )

    currentVariantIndex++

    if (currentVariantIndex >= this.variantTargets.length) {
      currentVariantIndex = 0
    }

    if (this.variantTargets.length > 0) {
      this.updateMainImage({ target: this.variantTargets[currentVariantIndex] })
    }

    this.touchstartX = 0
    this.touchendX = 0
  }

  scrollVariants(e) {
    const direction = parseInt(e.currentTarget.dataset.direction)
    const scrollAmount = direction * (this.variantsContainerTarget.clientWidth + 30)

    this.variantsContainerTarget.scrollLeft += scrollAmount
  }

  showVariants() {
    this.hasVariantsWrapperTarget && show(this.variantsWrapperTarget)
  }

  hideVariants() {
    this.hasVariantsWrapperTarget && hide(this.variantsWrapperTarget)
  }

  updateMainImage(e) {
    const variant = e.target.closest('.ProductHero-variant') || e.target
    const { image, name, price } = variant.dataset

    this.variantTargets.forEach((v) => v.classList.remove('u-borderBottom'))
    variant.classList.add('u-borderBottom')

    this.mainImageTarget.src = image
    this.mainImageTarget.srcset = image
    this.mainImageLinkTarget.href = variant.querySelector('a').href

    const brandLinkElement = this.element.querySelector('[data-product-target="brand"]')
    if (brandLinkElement) {
      brandLinkElement.href = variant.querySelector('a').href
    }

    const productNameLinkElement = this.element.querySelector('[data-product-target="name"]')
    if (productNameLinkElement) {
      productNameLinkElement.textContent = name
      productNameLinkElement.href = variant.querySelector('a').href
    }

    this.priceTarget.textContent = price
  }
}
