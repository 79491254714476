import { Controller } from '@hotwired/stimulus'
import { hide, show } from '../../lib/helpers'

export default class extends Controller {
  increment(event) {
    this.getElementsFromEventSelectors(event).forEach((countElement) => {
      countElement.textContent = parseInt(countElement.textContent, 10) + 1
      this.toggleCount(countElement)
    })
  }

  decrement(event) {
    this.getElementsFromEventSelectors(event).forEach((countElement) => {
      countElement.textContent = parseInt(countElement.textContent, 10) - 1
      this.toggleCount(countElement)
    })
  }

  toggleCount(element) {
    if (parseInt(element.textContent, 10) === 0) {
      hide(element)
    } else {
      show(element)
    }
  }

  getElementsFromEventSelectors(event) {
    const selectorValues = JSON.parse(event.currentTarget.dataset.selectors)
    const selectors = selectorValues.map((value) => `[data-content="${value.trim()}"]`)

    return document.querySelectorAll(selectors.join(','))
  }
}
