// Load Swiper for "IconCarousel" UI component

async function iconCarousel() {
  const iconCarousels = document.querySelectorAll('.js-icon-carousel')

  if (iconCarousels.length) {
    const { default: Swiper, Navigation, A11y } = await import('swiper')
    await Promise.all([import('swiper/css'), import('swiper/css/navigation'), import('swiper/css/a11y')])

    iconCarousels.forEach((iconCarousel) => {
      new Swiper(iconCarousel, {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 20,
        grabCursor: true,
        modules: [Navigation, A11y],
        navigation: {
          nextEl: iconCarousel.parentElement.querySelector('.swiper-button-next'),
          prevEl: iconCarousel.parentElement.querySelector('.swiper-button-prev'),
        },
        breakpoints: {
          480: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 60,
          },
        },
      })
    })
  }
}

document.addEventListener('starfish:render', iconCarousel)

export default iconCarousel
